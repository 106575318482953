/** @jsx jsx */
import { jsx } from 'theme-ui'

type InnerProps = {
  className?: string
  bottomPosition?: boolean
  children: React.ReactNode
}

const Inner = ({
  className = ``,
  bottomPosition = false,
  children,
}: InnerProps) => (
  <div
    sx={{
      width: 'full',
      textAlign: `left`,
      marginTop: bottomPosition ? 'auto' : '',
      marginBottom: bottomPosition ? '5rem' : '',
    }}
    className={className}
  >
    {children}
  </div>
)

export default Inner
